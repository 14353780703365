import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {Header} from "./components/Header";
import {Home} from "./pages/Home";

export default function App() {
  return (
    <>
      <Header />
      <Home />
    </>
  );
}