import '../assets/css/header/header.css';
import logo from '../assets/images/logo.jpg';
import vahan from '../assets/images/vahan.jpg';

export const Header = () => {
    window.onscroll = () =>{
        const header = document.querySelector('.nav_header');
        if(window.pageYOffset > 0){
            header.style.top = '0';
        }else{
            header.style.top = '9.1vw';
        }
    };
  return (
      <>
        <img src={vahan} id='header_first' alt="Նկարի խնդիր" />
        
          <nav className="navbar navbar-expand-lg nav_header">
              <div className="container-fluid">
                  <span className="navbar-brand">Վահան</span>
                  <img src={logo} id='logo' alt="Նկարի խնդիր" />
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                          aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
                          <li className="nav-item">
                              <a className="nav-link text-dark" href="#about_us">Մեր մասին</a>
                          </li>
                          <li className="nav-item">
                              <a className="nav-link text-dark" href="#contact_us">Կապ մեզ հետ</a>
                          </li>
                          {/* <li className="nav-item">
                              <a className="nav-link text-dark" href="#help_us">Աջակցել մեզ</a>
                          </li> */}
                      </ul>
                  </div>
              </div>
          </nav>
      </>
  )
}