import '../assets/css/home/home.css';
import full_screen from '../assets/video/full_screen.mp4';
import {FiPhoneCall} from "react-icons/fi";
import {BsFacebook} from "react-icons/bs";
import gallery1 from '../assets/images/gallery1.png';
import video1 from '../assets/video/video1.mp4';
import video2 from '../assets/video/video2.mp4';
import video3 from '../assets/video/video3.mp4';
import { useState } from 'react';

export const Home = () => {
    const videos = [video1, video2, video3];
    // const [phoneNum, setPhoneNum] = useState(false);
    
    // if(!phoneNum){
    //     setTimeout(()=>{
    //         setPhoneNum(true);
    //     }, 14700);
    // }else{
    //     setTimeout(()=>{
    //         setPhoneNum(false);
    //     }, 42500);
    // }
 return (
     <>
         <h1 className="d-none">Վահան Ռազմահայրենասիրական Հասարակական Կազմակերպություն, անվճար մարտավարական դասընթացներ, Vahan, hk, հկ, kamavor, jokat, martavarakan marzumner, banak, razmakan dproc, pashtpanutyun</h1>
         {/* <div id="video_main">
            <video src={full_screen} muted autoPlay loop className='full_video' />
            {
                phoneNum ? <div id="phone_contact">
                    043 24 07 22
                </div> : null
            }
         </div> */}
         
         <video src={full_screen} muted autoPlay loop className='full_video' />

         <div className="container" id='about_us'>
             <div className="row">
                 <div className="col-12">
                     <div className="title">Ով՞ ենք մենք</div>
                     <div className="text mt-3">Մենք Վահան (ՀԿ) -ն ենք, մեզ մոտ անվճար ուսուցանվում է հատուկ նշանակության ստորաբաժանման մարտավարություն
                     <hr/> Հրավիրում ենք մարզումների՝ <br/>
                         Տղաների և աղջիկների <br/>
                         1-խումբ 15-18 տարեկան պատանիներ <br/>
                         2-խումբ 19-ից բարձր մեծահասակներ <br/>
                         . Ուսուցանվում է՝ <br/>
                         -Մարտավարություն <br/>
                         -Կրակային պատրաստականություն <br/>
                         -Տեղագրություն <br/>
                         -Առաջին օգնություն <br/>
                         Ուսուցումը անցկացնում են պրոֆեսիոնալ հրահանգիչները <br/>
                         Մարզումները կազմակերպվում է ոչ աշխատանքային ժամերին <br/>
                         Անվճար
                     </div>
                 </div>
             </div>
         </div>

         <div className="container mt-5" id='contact_us'>
             <div className="row">
                 <div className="col-12">
                     <div className="title">Կապ մեզ հետ</div>
                 </div>
                 <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-5 text-center">
                     <div className="icon"><FiPhoneCall /></div>
                     {/* <a href="tel:+37443240722" className="info">043-24-07-22</a> */}
                     <a href="tel:+37494540423" className="info">094-54-04-23</a>
                 </div>
                 <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-5 text-center">
                     <div className="icon"><BsFacebook /></div>
                     <a href="https://www.facebook.com/profile.php?id=100093632753623" target='_blank' className="info">Ֆեյսբուքյան էջ</a>
                 </div>
             </div>
         </div>

         <div className="container my-5">
             <div className="row">
                 <div className="col-12">
                     <div className="gallery">
                         <img src={gallery1} className='img-fluid' alt="error image"/>
                     </div>
                 </div>
                 {
                     videos.map((video, index)=>{
                         return (
                             <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-5' key={index}>
                                 <video src={video} controls className='w-100'></video>
                             </div>
                         )
                     })
                 }
             </div>
             <div className="row">
                <div className='col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 mx-auto my-5 public_video'>
                        <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100093632753623%2Fvideos%2F259385867203552%2F&show_text=false&width=560&t=0" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                </div>

                <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto public_photo'>
                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02TXmDRDAtSDj5xJaWt1QCCjuVyW8cf46kPosYeed3h12UtWQgeFtinii9iR9x9y4kl%26id%3D100093632753623&show_text=true&width=500" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
                <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto public_photo'>
                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0rD6EpbjkzYU4ZiBGkQfGLEd4Yuk2P18AJvKVsZUVmYgX9RvQE1NSs1rCAioPZAjpl%26id%3D100093632753623&show_text=true&width=500" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>

                {/* <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mx-auto mt-5 public_help' id='help_us'>
                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0xDoGgUy3S9vR8shaCKWEtknqcgTSaRQdgwCdGV358WRHsY558SJK8WgLfmNXifKyl%26id%3D100093632753623&show_text=true&width=500" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div> */}
             </div>
         </div>
         <div id="footer">
            <div>
                Վահան © {new Date().getFullYear()} Բոլոր իրավունքները պաշտպանված են | by <a href='https://starcodeam.github.io/' target='_blank'>Star Code</a>
            </div>
         </div>
     </>
 );
}